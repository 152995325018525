const BACKEND_URI = "";
const USE_GPT4V = false;
const AZURE_SEARCH_SEMANTIC_RANKER = "free";
const USE_VECTORS = true;
const USE_USER_UPLOAD = false;
const SHOW_CHAT_HISTORY_BROWSER = false;
const SHOW_CHAT_HISTORY_COSMOS = true;

import { ChatAppResponse, ChatAppResponseOrError, ChatAppRequest, Config, SimpleAPIResponse, HistoryListApiResponse, HistoryApiResponse } from "./models";
import { useLogin, appServicesToken } from "../authConfig";

export function getHeaders(idToken: string | undefined): Record<string, string> {
    // If using login and not using app services, add the id token of the logged in account as the authorization
    if (useLogin && appServicesToken == null) {
        if (idToken) {
            return { Authorization: `Bearer ${idToken}` };
        }
    }

    return {};
}

export const configApi = (): Config => {
    return {
        showGPT4VOptions: USE_GPT4V,
        showSemanticRankerOption: AZURE_SEARCH_SEMANTIC_RANKER.toLowerCase() != "disabled",
        showVectorOption: USE_VECTORS,
        showUserUpload: USE_USER_UPLOAD,
        showChatHistoryBrowser: SHOW_CHAT_HISTORY_BROWSER,
        showChatHistoryCosmos: SHOW_CHAT_HISTORY_COSMOS
    };
};

export async function askApi(request: ChatAppRequest, idToken: string | undefined): Promise<ChatAppResponse> {
    const response = await fetch(`${BACKEND_URI}/ask`, {
        method: "POST",
        headers: { ...getHeaders(idToken), "Content-Type": "application/json" },
        body: JSON.stringify(request)
    });

    const parsedResponse: ChatAppResponseOrError = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse as ChatAppResponse;
}

export async function chatApi(request: ChatAppRequest, idToken: string | undefined): Promise<Response> {
    return await fetch(`${BACKEND_URI}/chat`, {
        method: "POST",
        headers: { ...getHeaders(idToken), "Content-Type": "application/json" },
        body: JSON.stringify(request)
    });
}

export function getCitationFilePath(citation: string): string {
    return `${BACKEND_URI}/content/${citation}`;
}

export async function uploadFileApi(request: FormData, idToken: string): Promise<SimpleAPIResponse> {
    const response = await fetch("/upload", {
        method: "POST",
        headers: getHeaders(idToken),
        body: request
    });

    if (!response.ok) {
        throw new Error(`Uploading files failed: ${response.statusText}`);
    }

    const dataResponse: SimpleAPIResponse = await response.json();
    return dataResponse;
}

export async function deleteUploadedFileApi(filename: string, idToken: string): Promise<SimpleAPIResponse> {
    const response = await fetch("/delete_uploaded", {
        method: "POST",
        headers: { ...getHeaders(idToken), "Content-Type": "application/json" },
        body: JSON.stringify({ filename })
    });

    if (!response.ok) {
        throw new Error(`Deleting file failed: ${response.statusText}`);
    }

    const dataResponse: SimpleAPIResponse = await response.json();
    return dataResponse;
}

export async function listUploadedFilesApi(idToken: string): Promise<string[]> {
    const response = await fetch(`/list_uploaded`, {
        method: "GET",
        headers: getHeaders(idToken)
    });

    if (!response.ok) {
        throw new Error(`Listing files failed: ${response.statusText}`);
    }

    const dataResponse: string[] = await response.json();
    return dataResponse;
}

export async function postChatHistoryApi(item: any, idToken: string): Promise<any> {
    const headers = await getHeaders(idToken);
    const response = await fetch("/chat_history", {
        method: "POST",
        headers: { ...headers, "Content-Type": "application/json" },
        body: JSON.stringify(item)
    });
    if (!response.ok) {
        throw new Error(`Posting chat history failed: ${response.statusText}`);
    }
    const dataResponse: any = await response.json();
    return dataResponse;
}
export async function getChatHistoryListApi(
    count: number,
    continuationToken: string | undefined,
    localId: String,
    idToken: string
): Promise<HistoryListApiResponse> {
    const headers = await getHeaders(idToken);
    let url = `${BACKEND_URI}/chat_history/sessions?count=${count}&id=${localId}`;
    if (continuationToken) {
        url += `&continuationToken=${continuationToken}`;
    }
    const response = await fetch(url.toString(), {
        method: "GET",
        headers: { ...headers, "Content-Type": "application/json" }
    });
    if (!response.ok) {
        throw new Error(`Getting chat histories failed: ${response.statusText}`);
    }
    const dataResponse: HistoryListApiResponse = await response.json();
    return dataResponse;
}
export async function getChatHistoryApi(id: string, idToken: string, localId: string): Promise<HistoryApiResponse> {
    const headers = await getHeaders(idToken);
    const response = await fetch(`/chat_history/sessions/${id}/${localId}`, {
        method: "GET",
        headers: { ...headers, "Content-Type": "application/json" }
    });
    if (!response.ok) {
        throw new Error(`Getting chat history failed: ${response.statusText}`);
    }
    const dataResponse: HistoryApiResponse = await response.json();
    return dataResponse;
}
export async function deleteChatHistoryApi(id: string, idToken: string, localId: string): Promise<any> {
    const headers = await getHeaders(idToken);
    const response = await fetch(`/chat_history/sessions/${id}/${localId}`, {
        method: "DELETE",
        headers: { ...headers, "Content-Type": "application/json" }
    });
    if (!response.ok) {
        throw new Error(`Deleting chat history failed: ${response.statusText}`);
    }
}
