import { History24Regular, Window32Filled, WindowHeaderVerticalRegular } from "@fluentui/react-icons";
import { Button, Tooltip } from "@fluentui/react-components";
import styles from "./HistoryButton.module.css";
interface Props {
    className?: string;
    onClick: () => void;
    disabled?: boolean;
    content?: string;
}
export const HistoryButton = ({ className, disabled, content, onClick }: Props) => {
    return (
        <div className={`${styles.container} ${className ?? ""}`}>
            <Tooltip content={content ? content : ""} relationship="description">
                <Button icon={<Window32Filled />} disabled={disabled} onClick={onClick}>
                    {/* Chat History */}
                </Button>
            </Tooltip>
        </div>
    );
};
