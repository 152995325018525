import { IHistoryProvider, Answers, HistoryProviderOptions, HistoryMetaData } from "./IProvider";
import { deleteChatHistoryApi, getChatHistoryApi, getChatHistoryListApi, postChatHistoryApi } from "../../api";
export class CosmosDBProvider implements IHistoryProvider {
    getProviderName = () => HistoryProviderOptions.CosmosDB;
    private continuationToken: string | undefined;
    private isItemEnd: boolean = false;
    resetContinuationToken() {
        this.continuationToken = undefined;
        this.isItemEnd = false;
    }
    async getNextItems(count: number, localId?: string, idToken?: string): Promise<HistoryMetaData[]> {
        if (this.isItemEnd) {
            return [];
        }
        try {
            const response = await getChatHistoryListApi(count, this.continuationToken, localId || "", idToken || "");
            this.continuationToken = response.continuation_token;
            if (!this.continuationToken) {
                this.isItemEnd = true;
            }
            return response.sessions.map(session => ({
                id: session.id,
                title: session.title,
                timestamp: session.timestamp
            }));
        } catch (e) {
            console.error(e);
            return [];
        }
    }
    async addItem(id: string, answers: Answers, entra_oid?: string, idToken?: string): Promise<void> {
        await postChatHistoryApi({ id, answers, entra_oid }, idToken || "");
        return;
    }
    async getItem(id: string, idToken?: string, localId?: string): Promise<Answers | null> {
        const response = await getChatHistoryApi(id, idToken || "", localId || "");
        return response.answers || null;
    }
    async deleteItem(id: string, idToken?: string, localId?: string): Promise<void> {
        await deleteChatHistoryApi(id, idToken || "", localId || "");
        return;
    }
}
