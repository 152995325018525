import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Progress.module.css";
import { Input, Button, Spinner, Tab, TabList, Textarea } from "@fluentui/react-components";
import { useLogin, getToken } from "../../authConfig";
import { useMsal } from "@azure/msal-react";
import { TextField } from "@fluentui/react";
import { useRef } from "react";
interface ProgressReport {
    executive_summary_1: string;
    progress_review_1: string;
    lessons_learned_1: string;
    conclusion_way_forward_1: string;
    executive_summary_2: string;
    progress_review_2: string;
    lessons_learned_2: string;
    conclusion_way_forward_2: string;
}

const Progress = () => {
    const navigate = useNavigate();
    const [year, setYear] = useState<string>("");
    const [quantumNumber, setQuantumNumber] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isGeneratingDoc, setIsGeneratingDoc] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [report, setReport] = useState<ProgressReport | null>(null);
    const [activeTab, setActiveTab] = useState<string>("executive_summary");
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [selectConclusion1, setSelectConclusion1] = useState<boolean>(false);
    const [selectConclusion2, setSelectConclusion2] = useState<boolean>(false);
    const [selectLessonsLearned1, setSelectLessonsLearned1] = useState<boolean>(false);
    const [selectLessonsLearned2, setSelectLessonsLearned2] = useState<boolean>(false);
    const [selectExecutiveSummary1, setSelectExecutiveSummary1] = useState<boolean>(false);
    const [selectExecutiveSummary2, setSelectExecutiveSummary2] = useState<boolean>(false);
    const [selectProgress1, setSelectProgress1] = useState<boolean>(false);
    const [selectProgress2, setSelectProgress2] = useState<boolean>(false);

    const [hideConclusion1, setHideConclusion1] = useState<boolean>(false);
    const [hideConclusion2, setHideConclusion2] = useState<boolean>(false);
    const [hideProgress1, setHideProgress1] = useState<boolean>(false);
    const [hideProgress2, setHideProgress2] = useState<boolean>(false);
    const [hideLessonsLearned1, setHideLessonsLearned1] = useState<boolean>(false);
    const [hideLessonsLearned2, setHideLessonsLearned2] = useState<boolean>(false);
    const [hideExecutiveSummary1, setHideExecutiveSummary1] = useState<boolean>(false);
    const [hideExecutiveSummary2, setHideExecutiveSummary2] = useState<boolean>(false);
    const [hideConclusionWayForward1, setHideConclusionWayForward1] = useState<boolean>(false);
    const [hideConclusionWayForward2, setHideConclusionWayForward2] = useState<boolean>(false);

    const client = useLogin ? useMsal().instance : undefined;

    const executiveSummary1Ref = useRef<HTMLDivElement>(null);
    const executiveSummary2Ref = useRef<HTMLDivElement>(null);
    const progressReview1Ref = useRef<HTMLDivElement>(null);
    const progressReview2Ref = useRef<HTMLDivElement>(null);
    const lessonsLearned1Ref = useRef<HTMLDivElement>(null);
    const lessonsLearned2Ref = useRef<HTMLDivElement>(null);
    const conclusionWayForward1Ref = useRef<HTMLDivElement>(null);
    const conclusionWayForward2Ref = useRef<HTMLDivElement>(null);

    // Add function to check if all sections have one option selected
    const isAllSectionsSelected = () => {
        // Check Executive Summary
        if (!selectExecutiveSummary1 && !selectExecutiveSummary2) return false;

        // Check Progress Review
        if (!selectProgress1 && !selectProgress2) return false;

        // Check Lessons Learned
        if (!selectLessonsLearned1 && !selectLessonsLearned2) return false;

        // Check Conclusion & Way Forward
        if (!selectConclusion1 && !selectConclusion2) return false;

        return true;
    };

    const handleGenerateReport = async () => {
        if (!year || !quantumNumber) {
            setMessage("Please fill in both Year and Quantum Number fields");
            return;
        }

        setIsLoading(true);
        setMessage("Generating report, please wait...");
        setReport(null);

        try {
            const token = client ? await getToken(client) : undefined;
            const response = await fetch("/progress_generator", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    ...(token && { Authorization: `Bearer ${token}` })
                },
                body: JSON.stringify({
                    year: year,
                    project_ids: quantumNumber
                })
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }

            const data = await response.json();
            setReport(data);
            setMessage("Progress report generated successfully!");
        } catch (error) {
            console.error("Error generating progress report:", error);
            setMessage(`Error generating progress report: ${error instanceof Error ? error.message : String(error)}`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleGenerateDocument = async () => {
        if (!report) {
            setMessage("Please generate a report first");
            return;
        }

        setIsGeneratingDoc(true);
        setMessage("Generating Word document...");

        try {
            // Prepare the data with the selected options
            const documentData = {
                executive_summary: selectExecutiveSummary1 ? report.executive_summary_1 : report.executive_summary_2,
                progress_review: selectProgress1 ? report.progress_review_1 : report.progress_review_2,
                lessons_learned: selectLessonsLearned1 ? report.lessons_learned_1 : report.lessons_learned_2,
                conclusion_way_forward: selectConclusion1 ? report.conclusion_way_forward_1 : report.conclusion_way_forward_2
            };

            const token = client ? await getToken(client) : undefined;
            const response = await fetch("/generate_report_document", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    ...(token && { Authorization: `Bearer ${token}` })
                },
                body: JSON.stringify(documentData)
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }

            // Get the blob from the response
            const blob = await response.blob();

            // Create a URL for the blob
            const url = window.URL.createObjectURL(blob);

            // Create a temporary link element
            const a = document.createElement("a");
            a.href = url;
            a.download = `progress_report_${year}_${quantumNumber}.docx`;

            // Append to the document and click
            document.body.appendChild(a);
            a.click();

            // Clean up
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);

            setMessage("Document downloaded successfully!");
        } catch (error) {
            console.error("Error generating document:", error);
            setMessage(`Error generating document: ${error instanceof Error ? error.message : String(error)}`);
        } finally {
            setIsGeneratingDoc(false);
        }
    };

    const renderReportContent = () => {
        if (!report) {
            return <p>No report generated yet. Fill in the fields above and click "Generate Progress Report".</p>;
        }

        switch (activeTab) {
            case "executive_summary":
                return <div className={styles.reportSection}>{report.executive_summary_1}</div>;
            case "progress_review":
                return <div className={styles.reportSection}>{report.progress_review_1}</div>;
            case "lessons_learned":
                return <div className={styles.reportSection}>{report.lessons_learned_1}</div>;
            case "conclusion_way_forward":
                return <div className={styles.reportSection}>{report.conclusion_way_forward_1}</div>;
            default:
                return <div className={styles.reportSection}>{report.executive_summary_1}</div>;
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.progressContainer}>
                <div className={styles.headerContainer}>
                    <h1 className={styles.title}>Project Progress Report</h1>
                    <div className={styles.chatEmptyStateSubtitle}>Generates project progress reports with thorough integration of data </div>
                    <div className={styles.chatEmptyStateSubtitle}>and adherence to provided information</div>
                </div>

                <div className={styles.inputContainer}>
                    <div className={styles.inputFieldContainer}>
                        <div className={styles.inputField}>
                            <label htmlFor="year">Year</label>
                            <Input
                                className={styles.inputFieldInput}
                                id="year"
                                value={year}
                                onChange={e => setYear(e.target.value)}
                                placeholder="Enter year (e.g., 2023)"
                            />
                        </div>

                        <div className={styles.inputField}>
                            <label htmlFor="quantumNumber">Quantum Project Number</label>
                            <Input
                                className={styles.inputFieldInput}
                                id="quantumNumber"
                                value={quantumNumber}
                                onChange={e => setQuantumNumber(e.target.value)}
                                placeholder="Enter quantum number"
                            />
                        </div>
                    </div>

                    <div className={styles.buttonContainer}>
                        <Button className={styles.generateButton} onClick={handleGenerateReport} disabled={isLoading}>
                            {isLoading ? <Spinner size="tiny" /> : "Generate Progress Report"}
                        </Button>

                        {
                            /* {report &&  */
                            <Button
                                className={styles.documentButton}
                                onClick={handleGenerateDocument}
                                disabled={isGeneratingDoc || !report || !isAllSectionsSelected()}
                            >
                                {isGeneratingDoc ? <Spinner size="tiny" /> : "Download as Word Document"}
                            </Button>
                        }
                    </div>

                    {message && <div className={styles.message}>{message}</div>}
                </div>

                <div className={styles.chatEmptyStateSubtitle}>Two versions will be generated for each section. Select the one that you prefer more.</div>

                <div className={styles.versionContainer}>
                    <div className={styles.reportContainer}>
                        <div className={styles.reportVersion}>Version 1</div>
                        <div className={styles.reportDescription}>
                            Provides a creative and detailed analysis, integrating all available data into a flowing, narrative style, ensuring thoroughness and
                            a cohesive story.
                        </div>
                    </div>
                    <div className={styles.reportContainer}>
                        <div className={styles.reportVersion}>Version 2</div>
                        <div className={styles.reportDescription}>
                            Delivers a verbatim-focused, concise analysis of the project's annual progress, more strictly adhering to the provided data and
                            avoiding additions.
                        </div>
                    </div>
                </div>

                {report && (
                    <div className={styles.tabContainer}>
                        <div className={styles.sectionContainer}>
                            <div className={styles.sectionHeader}>
                                <h3
                                    className={`${styles.sectionTitle} ${activeTab === "executive_summary" ? styles.activeSection : ""}`}
                                    onClick={() => setActiveTab("executive_summary")}
                                >
                                    Executive Summary
                                </h3>
                            </div>
                            {activeTab === "executive_summary" && (
                                <div className={styles.optionsContainer}>
                                    {!hideExecutiveSummary1 && (
                                        <div ref={executiveSummary1Ref} className={styles.editableSection}>
                                            <div style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                                                <input
                                                    type="checkbox"
                                                    checked={selectExecutiveSummary1}
                                                    onChange={e => {
                                                        setSelectExecutiveSummary1(e.target.checked);
                                                        setHideExecutiveSummary2(e.target.checked);
                                                    }}
                                                    style={{ marginRight: "8px" }}
                                                />
                                                <span>Version 1</span>
                                            </div>
                                            <TextField
                                                className={styles.editableTextarea}
                                                value={report?.executive_summary_1 || ""}
                                                onChange={(e, data) => setReport({ ...report!, executive_summary_1: data ? data : "" })}
                                                readOnly={false}
                                                multiline
                                                rows={19}
                                                borderless
                                                resizable={false}
                                            />
                                        </div>
                                    )}
                                    {!hideExecutiveSummary2 && (
                                        <div className={styles.editableSection}>
                                            <div style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                                                <input
                                                    type="checkbox"
                                                    checked={selectExecutiveSummary2}
                                                    onChange={e => {
                                                        setSelectExecutiveSummary2(e.target.checked);
                                                        setHideExecutiveSummary1(e.target.checked);
                                                    }}
                                                    style={{ marginRight: "8px" }}
                                                />
                                                <span>Version 2</span>
                                            </div>
                                            <TextField
                                                className={styles.editableTextarea}
                                                value={report?.executive_summary_2 || ""}
                                                onChange={(e, data) => setReport({ ...report!, executive_summary_2: data ? data : "" })}
                                                readOnly={false}
                                                multiline
                                                rows={19}
                                                borderless
                                                resizable={false}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}

                            <div className={styles.sectionHeader}>
                                <h3
                                    className={`${styles.sectionTitle} ${activeTab === "progress_review" ? styles.activeSection : ""}`}
                                    onClick={() => setActiveTab("progress_review")}
                                >
                                    Progress Review
                                </h3>
                            </div>
                            {activeTab === "progress_review" && (
                                <div className={styles.optionsContainer}>
                                    {!hideProgress1 && (
                                        <div className={styles.editableSection}>
                                            <div style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                                                <input
                                                    type="checkbox"
                                                    checked={selectProgress1}
                                                    onChange={e => {
                                                        setSelectProgress1(e.target.checked);
                                                        setHideProgress2(e.target.checked);
                                                    }}
                                                    style={{ marginRight: "8px" }}
                                                />
                                                <span>Version 1</span>
                                            </div>
                                            <TextField
                                                className={`${styles.editableTextarea}`}
                                                value={report?.progress_review_1 || ""}
                                                onChange={(e, data) => setReport({ ...report!, progress_review_1: data ? data : "" })}
                                                readOnly={false}
                                                multiline
                                                rows={19}
                                                borderless
                                                resizable={false}
                                            />
                                        </div>
                                    )}
                                    {!hideProgress2 && (
                                        <div className={styles.editableSection}>
                                            <div style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                                                <input
                                                    type="checkbox"
                                                    checked={selectProgress2}
                                                    onChange={e => {
                                                        setSelectProgress2(e.target.checked);
                                                        setHideProgress1(e.target.checked);
                                                    }}
                                                    style={{ marginRight: "8px" }}
                                                />
                                                <span>Version 2</span>
                                            </div>
                                            <TextField
                                                className={styles.editableTextarea}
                                                value={report?.progress_review_2 || ""}
                                                onChange={(e, data) => setReport({ ...report!, progress_review_2: data ? data : "" })}
                                                readOnly={false}
                                                multiline
                                                rows={19}
                                                borderless
                                                resizable={false}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}

                            <div className={styles.sectionHeader}>
                                <h3
                                    className={`${styles.sectionTitle} ${activeTab === "lessons_learned" ? styles.activeSection : ""}`}
                                    onClick={() => setActiveTab("lessons_learned")}
                                >
                                    Lessons Learned
                                </h3>
                            </div>
                            {activeTab === "lessons_learned" && (
                                <div className={styles.optionsContainer}>
                                    {!hideLessonsLearned1 && (
                                        <div className={styles.editableSection}>
                                            <div style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                                                <input
                                                    type="checkbox"
                                                    checked={selectLessonsLearned1}
                                                    onChange={e => {
                                                        setSelectLessonsLearned1(e.target.checked);
                                                        setHideLessonsLearned2(e.target.checked);
                                                    }}
                                                    style={{ marginRight: "8px" }}
                                                />
                                                <span>Version 1</span>
                                            </div>
                                            <TextField
                                                className={styles.editableTextarea}
                                                value={report?.lessons_learned_1 || ""}
                                                onChange={(e, data) => setReport({ ...report!, lessons_learned_1: data ? data : "" })}
                                                readOnly={false}
                                                multiline
                                                rows={19}
                                                borderless
                                                resizable={false}
                                            />
                                        </div>
                                    )}
                                    {!hideLessonsLearned2 && (
                                        <div className={styles.editableSection}>
                                            <div style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                                                <input
                                                    type="checkbox"
                                                    checked={selectLessonsLearned2}
                                                    onChange={e => {
                                                        setSelectLessonsLearned2(e.target.checked);
                                                        setHideLessonsLearned1(e.target.checked);
                                                    }}
                                                    style={{ marginRight: "8px" }}
                                                />
                                                <span>Version 2</span>
                                            </div>
                                            <TextField
                                                className={styles.editableTextarea}
                                                value={report?.lessons_learned_2 || ""}
                                                onChange={(e, data) => setReport({ ...report!, lessons_learned_2: data ? data : "" })}
                                                readOnly={false}
                                                multiline
                                                rows={19}
                                                borderless
                                                resizable={false}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}

                            <div className={styles.sectionHeader}>
                                <h3
                                    className={`${styles.sectionTitle} ${activeTab === "conclusion_way_forward" ? styles.activeSection : ""}`}
                                    onClick={() => setActiveTab("conclusion_way_forward")}
                                >
                                    Conclusion & Way Forward
                                </h3>
                            </div>
                            {activeTab === "conclusion_way_forward" && (
                                <div className={styles.optionsContainer}>
                                    {!hideConclusion1 && (
                                        <div className={styles.editableSection}>
                                            <div style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                                                <input
                                                    type="checkbox"
                                                    checked={selectConclusion1}
                                                    onChange={e => {
                                                        setSelectConclusion1(e.target.checked);
                                                        setHideConclusion2(e.target.checked);
                                                    }}
                                                    style={{ marginRight: "8px" }}
                                                />
                                                <span>Version 1</span>
                                            </div>
                                            <TextField
                                                className={styles.editableTextarea}
                                                value={report?.conclusion_way_forward_1 || ""}
                                                onChange={(e, data) => setReport({ ...report!, conclusion_way_forward_1: data ? data : "" })}
                                                readOnly={false}
                                                multiline
                                                rows={19}
                                                borderless
                                                resizable={false}
                                            />
                                        </div>
                                    )}
                                    {!hideConclusion2 && (
                                        <div className={styles.editableSection}>
                                            <div style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                                                <input
                                                    type="checkbox"
                                                    checked={selectConclusion2}
                                                    onChange={e => {
                                                        setSelectConclusion2(e.target.checked);
                                                        setHideConclusion1(e.target.checked);
                                                    }}
                                                    style={{ marginRight: "8px" }}
                                                />
                                                <span>Version 2</span>
                                            </div>
                                            <TextField
                                                className={styles.editableTextarea}
                                                value={report?.conclusion_way_forward_2 || ""}
                                                onChange={(e, data) => setReport({ ...report!, conclusion_way_forward_2: data ? data : "" })}
                                                readOnly={false}
                                                multiline
                                                rows={19}
                                                borderless
                                                resizable={false}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>

            {/* <div className={styles.content}>
                {isLoading ? (
                    <div className={styles.loadingContainer}>
                        <Spinner size="large" />
                        <p>Generating progress report...</p>
                    </div>
                ) : (
                    renderReportContent()
                )}
            </div> */}

            {/* <button className={styles.backButton} onClick={() => navigate("/chat")}>
                Back to Chat
            </button> */}
        </div>
    );
};

export default Progress;
