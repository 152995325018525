import { BotAppResponse } from "../../api";
export type HistoryMetaData = { id: string; title: string; timestamp: number };
export type Answers = [user: string, response: BotAppResponse][];
export const enum HistoryProviderOptions {
    None = "none",
    IndexedDB = "indexedDB",
    CosmosDB = "cosmosDB"
}
export interface IHistoryProvider {
    getProviderName(): HistoryProviderOptions;
    resetContinuationToken(): void;
    getNextItems(count: number, localId?: string, idToken?: string): Promise<HistoryMetaData[]>;
    addItem(id: string, answers: Answers, entra_oid?: string, idToken?: string): Promise<void>;
    getItem(id: string, idToken?: string, localId?: string): Promise<Answers | null>;
    deleteItem(id: string, idToken?: string, localAccountId?: string): Promise<void>;
}
