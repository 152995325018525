import { useState, useCallback } from "react";
import styles from "./HistoryItem.module.css";
import { DefaultButton } from "@fluentui/react";
import { Delete24Regular } from "@fluentui/react-icons";
export interface HistoryData {
    id: string;
    title: string;
    timestamp: number;
}
interface HistoryItemProps {
    item: HistoryData;
    onSelect: (id: string) => void;
    onDelete: (id: string) => void;
}
export function HistoryItem({ item, onSelect, onDelete }: HistoryItemProps) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleDelete = useCallback(() => {
        setIsModalOpen(false);
        onDelete(item.id);
    }, [item.id, onDelete]);
    return (
        <div className={styles.historyItem}>
            <button onClick={() => onSelect(item.id)} className={styles.historyItemButton}>
                <div className={styles.historyItemTitle} title={item.title}>
                    {item.title}
                </div>
            </button>
            <button onClick={() => setIsModalOpen(true)} className={styles.deleteButton} aria-label="delete this chat history">
                <Delete24Regular className={styles.deleteIcon} />
            </button>
            <DeleteHistoryModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onConfirm={handleDelete} />
        </div>
    );
}
function DeleteHistoryModal({ isOpen, onClose, onConfirm }: { isOpen: boolean; onClose: () => void; onConfirm: () => void }) {
    if (!isOpen) return null;
    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <h2 className={styles.modalTitle}>Delete Chat Session</h2>
                <p className={styles.modalDescription}>Are you sure you want to delete this chat?</p>
                <div className={styles.modalActions}>
                    <DefaultButton onClick={onClose} className={styles.modalCancelButton}>
                        Cancel
                    </DefaultButton>
                    <DefaultButton onClick={onConfirm} className={styles.modalConfirmButton}>
                        Delete
                    </DefaultButton>
                </div>
            </div>
        </div>
    );
}
