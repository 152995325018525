import { Spinner } from "@fluentui/react";
import { Tooltip } from "@fluentui/react-components";
import styles from "./HistorySidebar.module.css"; // Create this CSS file for styles
import { Answers, HistoryProviderOptions } from "../HistoryProviders/IProvider";
import { useMsal } from "@azure/msal-react";
import { getToken, useLogin } from "../../authConfig";
import { useEffect, useMemo, useRef, useState } from "react";
import { useHistoryManager, HistoryMetaData } from "../HistoryProviders";
import { HistoryData, HistoryItem } from "../HistoryItem";

import { WindowRegular } from "@fluentui/react-icons";
import { HistoryButton } from "../HistoryButton";

const HISTORY_COUNT_PER_LOAD = 20;

export const HistorySidebar = ({
    provider,
    isOpen,
    notify,
    onClose,
    onChatSelected
}: {
    provider: HistoryProviderOptions;
    isOpen: boolean;
    notify: boolean;
    onClose: () => void;
    onChatSelected: (answers: Answers) => void;
}) => {
    const historyManager = useHistoryManager(provider);
    const [history, setHistory] = useState<HistoryMetaData[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasMoreHistory, setHasMoreHistory] = useState(false);

    const client = useLogin ? useMsal().instance : undefined;

    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();

    useEffect(() => {
        if (!isOpen) return;
        if (notify) {
            setHistory([]);
            historyManager.resetContinuationToken();
            setHasMoreHistory(true);
        }
    }, [isOpen, notify]);
    const loadMoreHistory = async () => {
        setIsLoading(() => true);
        const token = client ? await getToken(client) : undefined;
        const items = await historyManager.getNextItems(HISTORY_COUNT_PER_LOAD, activeAccount?.localAccountId);
        if (items.length === 0) {
            setHasMoreHistory(false);
        }
        setHistory(prevHistory => [...prevHistory, ...items]);
        setIsLoading(() => false);
    };
    const handleSelect = async (id: string) => {
        const token = client ? await getToken(client) : undefined;
        const item = await historyManager.getItem(id, token, activeAccount?.localAccountId);
        if (item) {
            onChatSelected(item);
        }
    };
    const handleDelete = async (id: string) => {
        const token = client ? await getToken(client) : undefined;
        await historyManager.deleteItem(id, token, activeAccount?.localAccountId);
        setHistory(prevHistory => prevHistory.filter(item => item.id !== id));
    };
    const groupedHistory = useMemo(() => groupHistory(history), [history]);

    return (
        <div className={`${styles.sidebar} ${isOpen ? styles.open : ""}`}>
            <div className={styles.header}>
                <HistoryButton onClick={onClose} content="Close Chat History" />
            </div>
            <div className={styles.content}>
                {Object.entries(groupedHistory).map(([group, items]) => (
                    <div key={group} className={styles.group}>
                        <p className={styles.groupLabel}>{group}</p>
                        {items.map(item => (
                            <HistoryItem key={item.id} item={item} onSelect={handleSelect} onDelete={handleDelete} />
                        ))}
                    </div>
                ))}
                {isLoading && <Spinner style={{ marginTop: "10px" }} />}
                {history.length === 0 && !isLoading && <p>No History</p>}
                {hasMoreHistory && !isLoading && <InfiniteLoadingButton func={loadMoreHistory} />}
            </div>
        </div>
    );
};

function groupHistory(history: HistoryData[]) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const lastWeek = new Date(today);
    lastWeek.setDate(lastWeek.getDate() - 7);
    const lastMonth = new Date(today);
    lastMonth.setDate(lastMonth.getDate() - 30);
    return history.reduce(
        (groups, item) => {
            const itemDate = new Date(item.timestamp);
            let group;
            if (itemDate >= today) {
                group = "Today";
            } else if (itemDate >= yesterday) {
                group = "Yesterday";
            } else if (itemDate >= lastWeek) {
                group = "Last 7 days";
            } else if (itemDate >= lastMonth) {
                group = "Last 30 days";
            } else {
                group = itemDate.toLocaleDateString(undefined, { year: "numeric", month: "long" });
            }
            if (!groups[group]) {
                groups[group] = [];
            }
            groups[group].push(item);
            return groups;
        },
        {} as Record<string, HistoryData[]>
    );
}

const InfiniteLoadingButton = ({ func }: { func: () => void }) => {
    const buttonRef = useRef(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        if (buttonRef.current) {
                            func();
                        }
                    }
                });
            },
            {
                root: null,
                threshold: 0
            }
        );
        if (buttonRef.current) {
            observer.observe(buttonRef.current);
        }
        return () => {
            if (buttonRef.current) {
                observer.unobserve(buttonRef.current);
            }
        };
    }, []);
    return <button ref={buttonRef} onClick={func} />;
};
